<template>
  <div class="my-patients">
    <VToolbar dense>
      <VBtn text class="px-0" style="min-width: 36px">
        <VIcon>mdi-menu</VIcon>
      </VBtn>
      <VDivider vertical />
      <VToolbarTitle class="justify-start">{{ title[$route.params.for] }}</VToolbarTitle>
      <VSpacer />
      <VDivider vertical />
      <template v-for="menuItem in menuItems">
        <VMenu :key="menuItem.title" open-on-hover offset-y transition="slide-y-transition">
          <template #activator="{ on, attrs }">
            <VBtn :disabled="selected.length === 0" text v-bind="attrs" v-on="on">
              {{ menuItem.title }}
              <VIcon right>mdi-chevron-down</VIcon>
            </VBtn>
          </template>
          <VList dense>
            <VListItem
              v-for="(item, j) in menuItem.items"
              :key="j"
              :to="{
                name: item.name,
                params: { patient: patientUUID },
                query: { date: '2021-08-07' },
              }"
            >
              <VListItemTitle>{{ item.title }}</VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
      </template>
    </VToolbar>
    <VContainer fluid>
      <VDataTable
        v-model="selected"
        :headers="headers"
        :items="items"
        item-key="a"
        single-select
        :item-class="(i) => (selected.some((e) => e.a === i.a) ? 'secondary lighten-4' : '')"
        @click:row="(r, e) => e.select(true)"
      />
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MyPatients',
  data: () => ({
    yesAction: null,
    noAction: null,
    patientUUID: '0387e96a-4355-4e3c-970d-f41e8f3e23eb',
    selected: [],
    menuItems: [
      {
        title: '透析紀錄',
        items: [
          // {
          //   title: '透析醫囑',
          //   name: 'hd-record-medical-orders',
          // },
          {
            title: '護理紀錄',
            name: 'hd-record-nursing-record',
          },
          {
            title: '透析中給藥',
            name: 'hd-record-dosing-in-hd',
          },
          {
            title: '特殊交班',
            name: 'hd-record-special-shift',
          },
        ],
      },
      {
        title: '透析評估表單',
        items: [
          // 跌倒評估
          {
            title: '護理指導紀錄單',
            name: 'hd-assessment-nursing-guidance-record',
          },
          {
            title: '血管通路OP、PTA',
            name: 'hd-assessment-vascular-access-op-pta',
          },
          // 四項品質評估單
          // 護理計畫表
        ],
      },
    ],
    title: {
      onduty: '當班病患',
      mainly: '主護病患',
    },
    headers: [
      { text: '報到', value: 'a' },
      { text: '病患姓名', value: 'b' },
      { text: '病歷號碼', value: 'c' },
      { text: '病歷來源', value: 'd' },
      { text: '透析床號', value: 'e' },
      { text: '住院床號', value: 'f' },
      { text: '當班醫師', value: 'g' },
      { text: '主治醫師', value: 'h' },
      { text: '當班護理師', value: 'i' },
      { text: '主要護理師', value: 'j' },
      { text: '透析機號碼', value: 'k' },
    ],
    items: [
      {
        a: 'a1',
        b: 'b1',
        c: 'c1',
        d: 'd1',
        e: 'e1',
        f: 'f1',
        g: 'g1',
        h: 'h1',
        i: 'i1',
        j: 'j1',
        k: 'k1',
      },
      {
        a: 'a2',
        b: 'b2',
        c: 'c2',
        d: 'd2',
        e: 'e2',
        f: 'f2',
        g: 'g2',
        h: 'h2',
        i: 'i2',
        j: 'j2',
        k: 'k2',
      },
    ],
  }),
});
</script>

<style lang="scss">
.my-patients {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 800px;
        width: 800px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
          }

          th,
          td {
            min-width: 80px;
            text-align: center;
            padding: 0px;
            .v-input {
              border-radius: 0px;
              &:not(.v-input--is-focused)
                > .v-input__control
                > .v-input__slot:not(:hover):not(:active)
                > fieldset {
                color: transparent;
              }
            }
          }

          $border-colors: (
            'theme--light': rgba(0, 0, 0, 0.12),
            'theme--dark': rgba(255, 255, 255, 0.12),
          );
          @each $theme, $border-color in $border-colors {
            .#{$theme}.v-data-table > .v-data-table__wrapper > table > {
              thead,
              tbody {
                > tr > {
                  td,
                  th {
                    &:not(:last-child) {
                      border-right: thin solid $border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
