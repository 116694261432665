var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-patients"},[_c('VToolbar',{attrs:{"dense":""}},[_c('VBtn',{staticClass:"px-0",staticStyle:{"min-width":"36px"},attrs:{"text":""}},[_c('VIcon',[_vm._v("mdi-menu")])],1),_c('VDivider',{attrs:{"vertical":""}}),_c('VToolbarTitle',{staticClass:"justify-start"},[_vm._v(_vm._s(_vm.title[_vm.$route.params.for]))]),_c('VSpacer'),_c('VDivider',{attrs:{"vertical":""}}),_vm._l((_vm.menuItems),function(menuItem){return [_c('VMenu',{key:menuItem.title,attrs:{"open-on-hover":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"disabled":_vm.selected.length === 0,"text":""}},'VBtn',attrs,false),on),[_vm._v(" "+_vm._s(menuItem.title)+" "),_c('VIcon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('VList',{attrs:{"dense":""}},_vm._l((menuItem.items),function(item,j){return _c('VListItem',{key:j,attrs:{"to":{
              name: item.name,
              params: { patient: _vm.patientUUID },
              query: { date: '2021-08-07' },
            }}},[_c('VListItemTitle',[_vm._v(_vm._s(item.title))])],1)}),1)],1)]})],2),_c('VContainer',{attrs:{"fluid":""}},[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"a","single-select":"","item-class":function (i) { return (_vm.selected.some(function (e) { return e.a === i.a; }) ? 'secondary lighten-4' : ''); }},on:{"click:row":function (r, e) { return e.select(true); }},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }